import { Injectable, makeStateKey, StateKey, TransferState } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SsrUrlKeeperService {
  private readonly ssrPageUrl: StateKey<string> = makeStateKey('ssrPageUrl');

  constructor(private transferState: TransferState) {}

  setUrl(url: string): void {
    this.transferState.set(this.ssrPageUrl, url);
  }

  getUrl(): string | null {
    const ssrUrl = this.transferState.get(this.ssrPageUrl, null);
    this.transferState.remove(this.ssrPageUrl);
    return ssrUrl;
  }
}
